<div align="center" class="container">
    <!-- <div class="contactform">
        contact form
    </div>
    <div class="contactdesc">
        complete the form below & we will reach out to schedule your next pool service.
    </div>
    <br><mat-form-field appearance="outline">
      <input matInput placeholder="Name">
    </mat-form-field>
    <br><mat-form-field appearance="outline">
      <input matInput placeholder="Zipcode">
    </mat-form-field>
    <br><mat-form-field appearance="outline">
      <input matInput placeholder="Phone Number">
  </mat-form-field>
    <br><mat-form-field appearance="outline">
      <input matInput placeholder="Email">
    </mat-form-field>
    <br><mat-form-field appearance="outline">
        <input matInput placeholder="Preferred Day">
    </mat-form-field>
    <br><mat-form-field appearance="outline">
        <input matInput placeholder="Screen enclosure?">
    </mat-form-field>
    <br><mat-form-field appearance="outline">
        <input matInput placeholder="Gated subdivision?">
    </mat-form-field>
    <br><mat-form-field appearance="outline">
        <input matInput placeholder="Pets?">
    </mat-form-field>
    <br>
<button mat-button routerLink="/">submit form</button>
<br> -->
  </div>
