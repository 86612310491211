<div align="center">
<div class="container">
    <img src="assets/img/water.jpg" alt="water">
    <div class="title">
        leave your pool needs to us!
        <br> <div class="titlemore">we bring professional pool service to you.</div>
        <br>
        <div class="splashbutton">
            <button mat-button class="opaque" routerLink="/contact">take a splash!</button>
        </div> 
    </div>
  </div>
<div class="easyas">
    it's as easy as:
</div>
<div class="easylist">   
    <div class="numbers">1)</div><div class="selectpackage"> selecting a package</div> that meets your needs.
    <br><div class="numbers">2)</div> picking a <div class="selectpackage"> preferred day/time</div>.
    <br><div class="numbers">3)</div><div class="selectpackage"> confirming your appointment</div> with our scheduling specialist.
</div>
<div class="container">
    <img src="assets/img/pool.jpg" alt="water">
<div class="checkservices">
    <div>
        <button class="button button2" mat-button routerLink="/services">explore services</button>
    </div>
</div>
</div>
<br>
<mat-divider></mat-divider>
<div class="setsapart">
    <br>
    team splash - what sets us apart:
</div>
<div class="benefits">
    <img src="assets/img/droplet.svg" class="droplet" alt="water droplet"> simple scheduling & package options
    <br><img src="assets/img/droplet.svg" class="droplet" alt="water droplet"> our techs are cpo certified
    <br><img src="assets/img/droplet.svg" class="droplet" alt="water droplet"> safety is our standard
    <br><img src="assets/img/droplet.svg" class="droplet" alt="water droplet"> best prices in your area
    <br>
    <button class="button button3" mat-button routerLink="/jointeam">want to join<br>the team?</button>
</div>
<br>
<div class="callnow">
    call us now for more information! 1-844-6SPLASH
</div>
<br>
</div>