<div align="center">
    <div class="container">
        <img src="assets/img/sunglasses3.jpg" alt="water">
<div class="mission">
    our mission: service and safety delivered to you
</div>
<div class="missiondesc">
    <br>simple is key. we keep it simple.
    <!-- <br><br>started this easy access business out of necessity...
    <br>of getting our pool cleaned!
    <br><br>after many phone calls to local businesses,
    <br>we could not find a service or technician available. -->
    <br><br>splash4pools was created to provide pool owners 
    <br>with the ease of selecting and scheduling 
    <br>pool service from your device. 
    <br>
    <!-- <br><br>pool education and safety is a priority at splash4pools. 
    <br>we look forward to helping your family stay safe. and remember, 
    <br>the first step to pool safety is to learn how to swim! -->
</div>
<br><mat-divider></mat-divider>
<div class="container">
    <img src="assets/img/water.jpg" class="waterback" alt="water">

<div class="setsapart">
    <br>
    what sets us apart:
</div>
<div class="benefits">
    <img src="assets/img/droplet.svg" class="droplet" alt="water droplet"> simple scheduling & package options
    <br><img src="assets/img/droplet.svg" class="droplet" alt="water droplet"> we only hire cpo-certified professionals
    <br><img src="assets/img/droplet.svg" class="droplet" alt="water droplet"> safety is our standard
    <br><img src="assets/img/droplet.svg" class="droplet" alt="water droplet"> best prices in your area
    <br>
</div><br>
</div>
</div>
</div>
