<div align="center">
    <div class="container">
        <img src="assets/img/waterdrop.jpg" class="waterdrop" alt="water drop">
        <div class="title">
            <br> <div class="titlemore"></div>
            <br>
            <div class="splashbutton">
                <button class="button button2" mat-button routerLink="/contact">get started</button>
            </div> 
        </div>
      </div>

      <div align="center">
        <div class="container">
      <img src="assets/img/services_graphic2.jpg" class="servicesgraphic" alt="services">
    </div>
</div>